/* TODO: install and remove this, or dynamically load only in certain circumstances */
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.cdnfonts.com/css/satoshi');

body {
    -webkit-tap-highlight-color: transparent; /* iOS & Android */
    tap-highlight-color: transparent;         /* Standard (if supported) */
    /* touch-action: manipulation;*/ /* Prevent double-tap zoom */

    position: absolute;
    /*width: 100vw;
    height: 100vh;*/
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;

    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;

    /* Attributes common to all themes */
    /* --body-font: "Courier Prime", monospace; */
    --body-font: 'Satoshi', sans-serif;
    font-family: var(--body-font);
    text-align: center;
    font-size: 1.2em;
    --hex-value-font-weight: unset;

    /* Light theme specific attributes */
    --underlay-color: #ffffff;
    --background-color: #E8E8E8;
    background-color: var(--underlay-color);

    --icon-color-filter: initial;

    --body-color: #2e2e2e;
    color: var(--body-color);

    --hex-cell-enabled-inner-border-color: #c9d7de;
    --hex-cell-disabled-inner-border-color: #93a6af;

    --hex-disabled-color: #B0BEC5;
    --hex-color: #F5F5F5;
    --hex-bad-color: #D32F2F;
    --hex-good-color: #90EE90;
    --hex-reset-color: #607D8B;
    --hex-hover-color: #c4e1ef;

    --button-background-color: var(--background-color);
    --button-hover-background-color: #90A4AE;

    --modal-background-color: rgba(0, 0, 0, 0.5);
    --info-box-background-color: #d9d9d9;

    --input-background-color: var(--underlay-color);
    --input-border-color: #B0BEC5;

    --color-note-blue: #ADD8E6;
    /* Light Blue */
    --color-note-green: #cee5b9;
    /* Light Green */
    --color-note-yellow: #FFFF99;
    /* Light Yellow */
    --color-note-orange: #FFB347;
    /* Pastel Orange */
    --color-note-red: #FF9999;
    /* Light Red */
    --color-note-purple: #DA70D6;
    /* Orchid */
    --color-note-cyan: #008b8b;
    /* Light Cyan */
    --color-note-magenta: #FF77FF;
    /* Light Magenta */
    --color-note-brown: #D2B48C;
    /* Tan */

    /* Block quotes */
    --quote-border-color: #90A4AE;
    /* Soft gray-blue for the border */
    --quote-background-color: #F5F5F5;
    /* Light gray for the background */
    --quote-text-color: #2e2e2e;
    /* Dark gray for the text */
    --quote-symbol-color: #B0BEC5;
    /* Light gray-blue for the quote symbols */
    --quote-source-color: #607D8B;
    /* Slightly darker for the source */

    --daily-puzzle-active-color: #DA70D6;
}

body.dark-theme {
    --underlay-color: #1d1d20;
    --background-color: #36393b;
    --body-color: #E0E0E0;

    --icon-color-filter: invert(100%);

    --hex-cell-enabled-inner-border-color: #5b737e;
    --hex-cell-disabled-inner-border-color: #35414c;

    --hex-disabled-color: #1C2833;
    --hex-color: #455769;
    --hex-bad-color: #CF6679;
    --hex-good-color: #81C784;
    --hex-reset-color: #455A64;
    --hex-hover-color: #90a5af;

    --button-background-color: #1d1d20;
    --button-hover-background-color: #78909C;

    --info-box-background-color: #55575c;

    /* --input-background-color: #1d1d20;*/
    --input-background-color: var(--underlay-color);
    --input-border-color: #546E7A;

    --color-note-blue: #4169E1;
    /* Royal Blue */
    --color-note-green: #3CB371;
    /* Medium Sea Green */
    --color-note-yellow: #ccac00;
    /* Gold */
    --color-note-orange: #FF8C00;
    /* Dark Orange */
    --color-note-red: #FF6347;
    /* Tomato Red */
    --color-note-purple: #9932CC;
    /* Dark Orchid */
    --color-note-cyan: #20B2AA;
    /* Light Sea Green */
    --color-note-magenta: #FF00FF;
    /* Magenta */
    --color-note-brown: #8B4513;
    /* Saddle Brown */

    /* Block quotes */
    --quote-border-color: #5b737e;
    /* Adjusted for dark theme */
    --quote-background-color: #1d1d20;
    /* Dark background color */
    --quote-text-color: #E0E0E0;
    /* Light text color */
    --quote-symbol-color: #455769;
    /* Subtle color for quote symbols */
    --quote-source-color: #90a5af;
    /* Muted color for the source */

    --daily-puzzle-active-color: #9932CC;
}

body.aqua-theme {
    /* Underwater theme specific attributes */
    --underlay-color: #d8f0ff;
    /* Light Aqua */
    --background-color: #b8e0e8;
    /* Light Blue-Green */
    background-color: var(--underlay-color);

    --icon-color-filter: initial;

    --body-color: #2a3a4a;
    /* Deep Blue */
    color: var(--body-color);

    --hex-cell-enabled-inner-border-color: #98b8c2;
    /* Light Blue-Grey */
    --hex-cell-disabled-inner-border-color: #73939e;
    /* Medium Blue-Grey */

    --hex-disabled-color: #90aeb5;
    /* Muted Blue-Grey */
    --hex-color: #e0f7fa;
    /* Very Light Aqua */
    --hex-bad-color: #ff6f61;
    /* Coral Red */
    --hex-good-color: #48c9b0;
    /* Light Sea Green */
    --hex-reset-color: #5a7d8a;
    /* Slate Blue-Grey */
    --hex-hover-color: #a4d5e0;
    /* Pale Aqua */

    --button-background-color: var(--background-color);
    --button-hover-background-color: #81c8d0;
    /* Soft Aqua */

    --modal-background-color: rgba(0, 0, 0, 0.5);
    --info-box-background-color: #98b8c2;
    /* Light Blue-Green */

    --input-background-color: var(--underlay-color);
    --input-border-color: #90aeb5;
    /* Muted Blue-Grey */

    --color-note-blue: #76c7c0;
    /* Light Teal */
    --color-note-green: #b2d8c7;
    /* Mint Green */
    --color-note-yellow: #f7d488;
    /* Sand Yellow */
    --color-note-orange: #ff9e7d;
    /* Coral Orange */
    --color-note-red: #ff6f61;
    /* Coral Red */
    --color-note-purple: #9a67d4;
    /* Lavender Purple */
    --color-note-cyan: #4da6ff;
    /* Aqua Blue */
    --color-note-magenta: #d17aeb;
    /* Light Violet */
    --color-note-brown: #a57d4e;
    /* Sandy Brown */

    /* Block quotes */
    --quote-border-color: #98b8c2;
    /* Light Blue-Grey */
    --quote-background-color: #d8f0ff;
    /* Light Aqua */
    --quote-text-color: #2a3a4a;
    /* Deep Blue */
    --quote-symbol-color: #73939e;
    /* Medium Blue-Grey */
    --quote-source-color: #5a7d8a;
    /* Slate Blue-Grey */

    --daily-puzzle-active-color: #9a67d4;
}

body.jungle-theme {
    /* Jungle theme specific attributes */
    --underlay-color: #e0f2f1;
    /* Light Mint Green */
    --background-color: #c8e6c9;
    /* Soft Green */
    background-color: var(--underlay-color);

    --icon-color-filter: initial;

    --body-color: #1b5e20;
    /* Dark Jungle Green */
    color: var(--body-color);

    --hex-cell-enabled-inner-border-color: #66bb6a;
    /* Medium Green */
    --hex-cell-disabled-inner-border-color: #388e3c;
    /* Dark Green */

    --hex-disabled-color: #a5d6a7;
    /* Light Green */
    --hex-color: #e8f5e9;
    /* Very Light Green */
    --hex-bad-color: #d32f2f;
    /* Dark Red */
    --hex-good-color: #66bb6a;
    /* Medium Green */
    --hex-reset-color: #4caf50;
    /* Jungle Green */
    --hex-hover-color: #a5d6a7;
    /* Light Green */

    --button-background-color: var(--background-color);
    --button-hover-background-color: #81c784;
    /* Light Green */

    --modal-background-color: rgba(0, 0, 0, 0.5);
    --info-box-background-color: #82c685;
    /* Pale Green */

    --input-background-color: var(--underlay-color);
    --input-border-color: #81c784;
    /* Light Green */

    --color-note-blue: #42a5f5;
    /* Sky Blue */
    --color-note-green: #66bb6a;
    /* Medium Green */
    --color-note-yellow: #ffee58;
    /* Bright Yellow */
    --color-note-orange: #ffb74d;
    /* Orange */
    --color-note-red: #ef5350;
    /* Bright Red */
    --color-note-purple: #ab47bc;
    /* Bright Purple */
    --color-note-cyan: #26c6da;
    /* Bright Cyan */
    --color-note-magenta: #ec407a;
    /* Bright Magenta */
    --color-note-brown: #a1887f;
    /* Medium Brown */

    /* Block quotes */
    --quote-border-color: #66bb6a;
    /* Medium Green */
    --quote-background-color: #e0f2f1;
    /* Light Mint Green */
    --quote-text-color: #1b5e20;
    /* Dark Jungle Green */
    --quote-symbol-color: #388e3c;
    /* Dark Green */
    --quote-source-color: #4caf50;
    /* Jungle Green */

    --daily-puzzle-active-color: #ab47bc;
}

body.royal-theme {
    /* Jungle theme specific attributes */
    --underlay-color: #10061F;
    /* Dark Purple */

    --background-color: #3C2B55;
    /* Deep Purple */
    background-color: var(--underlay-color);

    --icon-color-filter: invert(100%);
    /* Ensuring icons contrast well against the dark background */

    --body-color: #E0E0E0;
    /* Light Gray for readability against dark backgrounds */
    color: var(--body-color);

    --hex-cell-enabled-inner-border-color: #462167;
    /* Vibrant Purple */
    --hex-cell-disabled-inner-border-color: #2E0F48;
    /* Dark Purple */

    --hex-disabled-color: #2E0F48;
    /* Dark Purple */
    --hex-color: #6C57A3;
    /* Softened Purple for better contrast */
    --hex-bad-color: #CF6679;
    /* Soft Red for high visibility */
    --hex-good-color: #81C784;
    /* Soft Green for good contrast */
    --hex-reset-color: #5A4B89;
    /* Medium Purple for neutral actions */
    --hex-hover-color: #8574B9;
    /* Lighter Purple for hover state */

    --button-background-color: var(--background-color);
    --button-hover-background-color: #6C57A3;
    /* Softer Purple for button hover state */

    --modal-background-color: rgba(16, 6, 31, 0.8);
    /* Slightly Transparent Dark Purple for modals */
    --info-box-background-color: #2E0F48;
    /* Dark Purple with slight transparency for info boxes */

    --input-background-color: var(--underlay-color);
    --input-border-color: #1E0B37;
    /* Vibrant Purple for input borders */

    /* Color Notes with Contrast */
    --color-note-blue: #738ADA;
    /* Lighter Blue for better contrast */
    --color-note-green: #A8C686;
    /* Softer Green for readability */
    --color-note-yellow: #F7DA66;
    /* Bright Yellow for clear visibility */
    --color-note-orange: #F79C66;
    /* Soft Orange for readability */
    --color-note-red: #F76D66;
    /* Bright Red for emphasis */
    --color-note-purple: #A685D6;
    /* Light Purple for better visibility */
    --color-note-cyan: #66C7D6;
    /* Bright Cyan for contrast */
    --color-note-magenta: #D666D6;
    /* Bright Magenta for emphasis */
    --color-note-brown: #8B6C66;
    /* Soft Brown for contrast */

    /* Block quotes */
    --quote-border-color: #462167;
    /* Vibrant Purple */
    --quote-background-color: #2E0F48;
    /* Dark Purple */
    --quote-text-color: #E0E0E0;
    /* Light Gray for readability */
    --quote-symbol-color: #6C57A3;
    /* Softer Purple for symbols */
    --quote-source-color: #8574B9;
    /* Lighter Purple for source text */

    --daily-puzzle-active-color: #A685D6;
}

body.pumpkin-theme {
    --underlay-color: #2a1b0c;
    /* Dark pumpkin base */
    --background-color: #3e2612;
    /* Rich pumpkin brown */
    --body-color: #E8D4B0;
    /* Warm, light tan text color */

    --icon-color-filter: invert(90%);

    --hex-cell-enabled-inner-border-color: #7a4e20;
    /* Deep pumpkin orange */
    --hex-cell-disabled-inner-border-color: #5e3920;
    /* Dark burnt orange */

    --hex-disabled-color: #2a1b0c;
    /* Dark pumpkin */
    --hex-color: #d1851a;
    /* Pumpkin orange */
    --hex-bad-color: #ff6347;
    /* Tomato red */
    --hex-good-color: #609477;
    /* Soft orange glow */
    --hex-reset-color: #d1851a;
    /* Same as pumpkin orange */
    --hex-hover-color: #e89e48;
    /* Light pumpkin */

    --button-background-color: #3e2612;
    /* Rich pumpkin brown */
    --button-hover-background-color: #e89e48;
    /* Light pumpkin */

    --info-box-background-color: #5e3920;
    /* Burnt orange background */

    --input-background-color: var(--underlay-color);
    --input-border-color: #7a4e20;
    /* Deep pumpkin orange */

    --color-note-blue: #4169E1;
    /* Royal Blue */
    --color-note-green: #3CB371;
    /* Medium Sea Green */
    --color-note-yellow: #ffcc00;
    /* Bright pumpkin yellow */
    --color-note-orange: #FF8C00;
    /* Dark Orange */
    --color-note-red: #FF6347;
    /* Tomato Red */
    --color-note-purple: #9932CC;
    /* Dark Orchid */
    --color-note-cyan: #20B2AA;
    /* Light Sea Green */
    --color-note-magenta: #FF00FF;
    /* Magenta */
    --color-note-brown: #8B4513;
    /* Saddle Brown */

    --quote-border-color: #7a4e20;
    /* Deep pumpkin orange */
    --quote-background-color: #2a1b0c;
    /* Dark pumpkin base */
    --quote-text-color: #E8D4B0;
    /* Warm, light tan text */
    --quote-symbol-color: #d1851a;
    /* Pumpkin orange */
    --quote-source-color: #e89e48;
    /* Light pumpkin */

    --daily-puzzle-active-color: #9932CC;
}

body.festive-theme {
    --underlay-color: #0a1913; /* Dark evergreen */
    --background-color: #132821; /* Rich forest green */
    --body-color: #f1f1f1; /* Crisp off-white */

    --icon-color-filter: invert(100%);

    --hex-cell-enabled-inner-border-color: #6e8f78; /* Balanced muted green */
    --hex-cell-disabled-inner-border-color: #3d5246; /* Subtle gray-green */

    --hex-disabled-color: #25332d; /* Deep gray-green */
    --hex-color: #a9c9a6; /* Softer pastel green */
    --hex-bad-color: #c04f4f; /* Warmer, deeper crimson */
    --hex-good-color: #81b081; /* Vibrant holiday green */
    --hex-reset-color: #567e63; /* Balanced pine green */
    --hex-hover-color: #b22222; /* Fresh mint green for hover */

    --button-background-color: #567e63; /* Default forest green */
    --button-hover-background-color: #b22222; /* Candy-cane red for hover/active */

    --info-box-background-color: #1e3d33; /* Deeper forest green */

    --input-background-color: var(--underlay-color);
    --input-border-color: #c8b491; /* Neutral gold-like tone */

    --color-note-blue: #a6d3eb; /* Frosty ice blue */
    --color-note-green: #16a132; /* Fresh evergreen */
    --color-note-yellow: #e8c44f; /* Bright goldenrod */
    --color-note-orange: #d89a6e; /* Gingerbread orange */
    --color-note-red: #bf5757; /* Warm, festive red */
    --color-note-purple: #a687ba; /* Light plum */
    --color-note-cyan: #7ecfc3; /* Soft teal */
    --color-note-magenta: #d88fa5; /* Rose pink */
    --color-note-brown: #947151; /* Toasty brown */

    --quote-border-color: #6e8f78; /* Balanced muted green */
    --quote-background-color: #132821; /* Same as background for consistency */
    --quote-text-color: #f1f1f1; /* Crisp off-white */
    --quote-symbol-color: #b22222; /* Bright goldenrod */
    --quote-source-color: #8cbf96; /* Fresh evergreen */

    --daily-puzzle-active-color: #a687ba;
}

body.e-ink {
    --underlay-color: #CCCCCC;
    --background-color: #FFFFFF; /* Pure white */
    --body-color: #000000; /* Pure black text */

    --icon-color-filter: invert(0%); /* Icons remain black */

    --hex-cell-enabled-inner-border-color: #000000; /* Solid black borders */
    --hex-cell-disabled-inner-border-color: #BBBBBB; /* Medium-light gray for disabled cells */

    --hex-disabled-color: #999999; /* Light gray for disabled hexes */
    --hex-color: #EEEEEE; /* Pure black for primary hexes */
    --hex-bad-color: #FF0000; /* Bright red for errors */
    --hex-good-color: #00FF00; /* Bright green for successes */
    --hex-reset-color: #000000; /* Pure black for reset */
    --hex-hover-color: #444444; /* Dark gray for hover states */

    /* Updated button styles */
    --button-background-color: transparent; /* Transparent background */
    --button-text-color: #000000; /* Pure black text */
    --button-hover-background-color: #DDDDDD; /* Light gray for hover state */
    --button-hover-text-color: #000000; /* Pure black text on hover */

    --info-box-background-color: #FFFFFF; /* White background for info boxes */
    --info-box-border-color: #000000; /* Black border for strong contrast */

    --input-background-color: #FFFFFF; /* White background for input fields */
    --input-border-color: #DDDDDD; /* Pure black border */

    /* High-contrast note colors */
    --color-note-blue: #0000FF; /* Vivid blue */
    --color-note-green: #00AA00; /* Dark green for sharper contrast */
    --color-note-yellow: #FFD700; /* Bright golden yellow */
    --color-note-orange: #FF4500; /* Bright red-orange */
    --color-note-red: #FF0000; /* Intense red */
    --color-note-purple: #800080; /* Deep purple */
    --color-note-cyan: #00FFFF; /* Bright cyan */
    --color-note-magenta: #FF00FF; /* Bold magenta */
    --color-note-brown: #5A2D0C; /* Deep brown for strong contrast */

    /* Block quotes */
    --quote-border-color: #000000; /* Solid black border */
    --quote-background-color: #FFFFFF; /* Pure white background */
    --quote-text-color: #000000; /* Pure black text */
    --quote-symbol-color: #000000; /* Pure black symbols */
    --quote-source-color: #555555; /* Medium-dark gray for source details */

    /* Miscellaneous */
    --focus-outline-color: #FF0000; /* Bright red focus outlines for accessibility */
    --daily-puzzle-active-color: #333333;
}


a {
    color: var(--body-color);
}

input,
select,
textarea,
button {
    color: var(--body-color) !important;
    /* Light gray for text */
    background-color: var(--input-background-color) !important;
    /* Dark background */
    border: 1px solid var(--input-border-color) !important;
    /* Styled border */
    padding: 8px;
    border-radius: 4px;
    font-family: inherit;
    /* Consistent font styling */
    font-size: 0.9em;
}

select {
    cursor: pointer;
}

.content-container {
    padding-top: 5px;
    padding-bottom: 5px;

    position: relative;
    background-color: var(--background-color);
    border-radius: 0 20px 0 0;
    width: 97%;
    left: 1.5%;

    flex-grow: 1;
    z-index: 0;
}

.main-content {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
}

h1 {
    font-size: 2.5em;
}

.left-content p,
ul,
li,
pre,
code {
    text-align: left;
}

.quote-container {
    background-color: var(--quote-background-color);
    border-left: 5px solid var(--quote-border-color);
    padding: 20px;
    margin: 20px 0;
    color: var(--quote-text-color);
    position: relative;
}

blockquote {
    font-style: italic;
    font-size: 1.2em;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    position: relative;
}

blockquote:before {
    content: "“";
    font-size: 3em;
    color: var(--quote-symbol-color);
    position: absolute;
    top: -10px;
    left: -10px;
    line-height: 1;
}

blockquote:after {
    content: "”";
    font-size: 3em;
    color: var(--quote-symbol-color);
    position: absolute;
    bottom: -20px;
    right: -10px;
    line-height: 1;
}

cite {
    display: block;
    margin-top: 10px;
    text-align: right;
    font-weight: bold;
    color: var(--quote-source-color);
    font-style: normal;
    /* Prevents italicization of the cite */
}

hexakai-board {
    --color: var(--body-color);
    --font: var(--body-font);

    --color-note-1: var(--color-note-blue);
    --color-note-2: var(--color-note-green);
    --color-note-3: var(--color-note-yellow);
    --color-note-4: var(--color-note-orange);
    --color-note-5: var(--color-note-red);
    --color-note-6: var(--color-note-purple);
    --color-note-7: var(--color-note-cyan);
    --color-note-8: var(--color-note-magenta);
    --color-note-9: var(--color-note-brown);
}

@media screen and (max-width: 1500px) {
    h1 {
        margin: 9px;
        font-size: 1.5em;
    }

    nav {
        font-size: 0.9em;
        margin-top: 3px;
        margin-bottom: 3px;
    }
}

@media screen and (max-width: 1200px) {
    .main-content {
        width: 95%;
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
}

@media screen and (max-width: 700px) {
    body {
        font-size: 0.9em;
    }

    .content-container {
        width: 100%;
        left: 0;
    }
}